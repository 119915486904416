/** @jsx jsx */
import { jsx } from 'theme-ui';

interface Props {
  width?: string | string[];
  height?: string | string[];
  invertColors?: boolean;
}

const ModeSwitcherMobile: React.FC<Props> = ({
  width,
  height = '50px',
  invertColors,
}) => (
  <svg
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width,
      height,
    }}
  >
    <circle
      cx="32.9999"
      cy="32.9997"
      r="25"
      transform="rotate(-21 32.9999 32.9997)"
      sx={{ fill: invertColors ? 'text' : 'background' }}
    />
    <path
      d="M33.9284 12.2542C35.1046 12.3471 35.5414 13.9585 34.4027 14.5808C18.5843 23.9867 27.9934 49.503 46.4413 45.7272C47.6883 45.4731 48.4029 46.9329 47.4086 47.96C37.0858 58.5621 18.9097 54.8925 13.3949 40.526C7.84813 26.0762 19.0597 11.1146 33.9284 12.2542Z"
      sx={{ fill: invertColors ? 'background' : 'text' }}
    />
  </svg>
);

export { ModeSwitcherMobile };
