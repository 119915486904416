/** @jsx jsx */
import * as React from 'react';
import { jsx, useColorMode } from 'theme-ui';
import { Box, Flex, Button } from 'rebass';
import { Link as BaseLink } from 'gatsby';
import {
  LocalizedLink as Link,
  LocalizedLink,
} from '../components/LocalizedLink';

import { useLocale } from '../i18n/LocaleContext';
import { useTranslation } from '../i18n/TranslationContext';
import { useHeaderLinks } from './default-header-links';

import Logo from './Logo';
import { ModeSwitcherMobile } from './ModeSwitcherMobile';

const activeStyles = {
  fontWeight: 'bold',
  borderBottomStyle: 'solid',
  borderBottomWidth: '3px',
  borderColor: 'background',
};

type Props = {
  logoOnScreen: boolean;
};
const MobileNav: React.FC<Props> = ({ logoOnScreen }) => {
  const links = useHeaderLinks();
  const [menuActive, setMenuState] = React.useState(false);
  const { locale, basePath } = useLocale();
  const t = useTranslation();

  const [mode, setMode] = useColorMode();
  const landscapeHeightThreshold = '460px';
  const landscapeMediaQuery = `@media screen and (max-height: ${landscapeHeightThreshold})`;
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: ['block', 'block', 'none'],
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          bg: 'black',
          width: '100%',
          height: '100vh',
          opacity: `${menuActive ? '0.5' : '0'}`,
          transition:
            'opacity 0.125s cubic-bezier(0.4, 0.0, 0.2, 1), translateY 0s',
          position: 'absolute',
          top: 0,
          transform: `translateY(${menuActive ? '-100%' : '0'})`,
        }}
        onClick={() => setMenuState(false)}
      />
      <Box
        sx={{
          backgroundColor: 'text',
          width: !logoOnScreen || menuActive ? '100%' : '85px',
          transitionTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1), ease',
          transition: 'transform 0.225s, width 0.225s',
          position: 'absolute',
          top: 0,
          right: 0,
          transform: `translateY(${menuActive ? '-100%' : '-62px'})`,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent:
              !logoOnScreen || menuActive ? 'space-between' : 'flex-end',
            height: '30px',
            px: 3,
            py: 4,
            borderBottom: '1px solid',
            borderColor: 'background',
          }}
        >
          {(!logoOnScreen || menuActive) && (
            <LocalizedLink to="/">
              <Logo
                type="interaction-21glitch"
                fill="background"
                sx={{ height: '30px', width: '115px' }}
              />
            </LocalizedLink>
          )}
          <button
            role="button"
            sx={{
              variant: 'variants.navlink',
              color: 'background',
              textDecoration: 'none',
              appearance: 'none',
              border: 'none',
              background: 'none',
              outline: 'none',
              '&:hover': { textDecoration: 'none' },
            }}
            aria-expanded={menuActive}
            onClick={() => setMenuState(true)}
          >
            {t('nav_menu')}
          </button>
        </Flex>
        {/*LANGUAGE*/}
        <Flex
          sx={{
            borderBottom: '1px solid',
            borderColor: 'background',
          }}
        >
          <BaseLink
            to={`${basePath === '/fr' ? '' : basePath}`}
            sx={{
              variant: 'variants.navlink',
              color: 'background',
              px: 3,
              py: '12px',
              borderRight: '1px solid',
              borderColor: 'background',
              ...(locale === 'en' ? activeStyles : {}),
              [landscapeMediaQuery]: {
                py: 2,
              },
            }}
          >
            EN
          </BaseLink>
          <BaseLink
            to={`/fr${basePath === '/fr' ? '' : basePath}`}
            sx={{
              variant: 'variants.navlink',
              color: 'background',
              px: 3,
              py: '12px',
              borderRight: '1px solid',
              borderColor: 'background',
              ...(locale === 'fr' ? activeStyles : {}),
              [landscapeMediaQuery]: {
                py: 2,
              },
            }}
          >
            FR
          </BaseLink>
        </Flex>
        <nav>
          {links.map(link => {
            return (
              <Link
                key={link.label}
                sx={{
                  px: 3,
                  py: '12px',
                  display: 'block',
                  variant: 'variants.navlink',
                  color: 'background',
                  '@media screen and (max-height: 460px)': {
                    py: 2,
                  },
                  ...(link.path.includes(basePath.split('/')[1]) &&
                  basePath !== '/'
                    ? activeStyles
                    : {
                        borderBottom: '1px solid',
                        borderColor: 'background',
                      }),
                }}
                to={link.path}
              >
                {link.label}
              </Link>
            );
          })}
        </nav>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-around',
            py: 4,
            [landscapeMediaQuery]: {
              pb: 0,
              pt: 2,
              flexDirection: 'row',
              justifyContent: 'center',
              a: { ml: 3, alignSelf: 'center' },
            },
          }}
        >
          <Button
            variant="secondary"
            as="a"
            sx={{
              fontSize: 2,
              [landscapeMediaQuery]: { mt: 0, ml: 3, fontSize: 2 },
            }}
            href="https://shop.ixda.org/"
            target="_blank"
          >
            {t('nav_get_merch')}
          </Button>
        </Flex>
        <Flex
          sx={{
            position: 'relative',
            px: 2,
            textAlign: 'right',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '60px',
          }}
        >
          <Box onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}>
            <ModeSwitcherMobile />
          </Box>
          <span
            sx={{ variant: 'variants.navlink', color: 'background' }}
            onClick={() => setMenuState(false)}
          >
            {t('nav_close')}
          </span>
        </Flex>
      </Box>
    </Box>
  );
};

export { MobileNav };
