/** @jsx jsx */
import { jsx } from 'theme-ui';

interface Props {
  width?: string | string[];
  height?: string | string[];
  fill?: string;
}
const IXDALogo: React.FC<Props> = ({
  width,
  height = '50px',
  fill = 'text',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width,
      height,
      fill,
    }}
    preserveAspectRatio="xMinYMin"
    viewBox="0 0 113 40"
    aria-hidden="true"
  >
    <path d="M45 5h-4a1.7 1.7 0 00-.6.2l-.3.1H40v.2l-.3.1-9.8 10a.3.3 0 01-.4 0l-9.8-10a1.8 1.8 0 00-.9-.5H14c-.4.1-.8.3-1 .7-.3.3-.4.7-.4 1.1V11.7l.5.6 9.8 10a.3.3 0 010 .3l-9.8 10-.4.6a1.7 1.7 0 00-.1.3V38c0 .5.1 1 .5 1.3.3.4.8.6 1.3.6h4.3a1.8 1.8 0 001-.5l9.8-10a.3.3 0 01.3 0l9.9 10c.2.2.6.4.9.4H45c.5 0 1-.1 1.3-.5.3-.3.5-.8.5-1.2v-4.1-.4l-.1-.3-.4-.6-9.9-10a.3.3 0 010-.3l9.9-10a1.8 1.8 0 00.5-1.3V7c0-.6-.2-1-.5-1.4-.4-.3-.8-.5-1.3-.5zm0 1.7h.2V11.2H41h-.1V7a.2.2 0 010-.3H45zM14.2 7v-.1H18.4a.2.2 0 01.1 0v4.3h-4.1a.2.2 0 01-.2 0V6.8zm.1 31.3a.2.2 0 01-.1-.2v-4.1-.1a.2.2 0 01.1 0h4.2v4.3h-4.1zm26.7 0l-.1-.1V33.9v-.1H45a.2.2 0 01.2 0v4.3H41zM35.3 21a2 2 0 000 2.7l8.2 8.3H41c-.5 0-1 .2-1.3.5-.3.3-.5.8-.5 1.3v2.5L31 28.1a1.9 1.9 0 00-2.7 0l-8.1 8.3v-2.5c0-.5-.2-1-.5-1.3-.4-.3-.8-.5-1.3-.5h-2.5l8.2-8.3a2 2 0 00.4-2.1 2 2 0 00-.4-.6l-8.2-8.3h2.5c.4 0 1-.2 1.2-.5.4-.4.6-.8.6-1.3V8.5l8.1 8.2a1.9 1.9 0 002.1.5l.6-.5 8.2-8.2V11c0 .5.2 1 .5 1.3.4.3.8.5 1.3.5h2.5l-8.2 8.3zM5.5 3.3H0V39h5.4V3.3zM66.4 3.3H54V39h12c5.2 0 8.9-2.6 11-6 1.5-2.4 2-3.8 2-11.9 0-7.4-.2-8.8-2-11.8a12 12 0 00-10.7-6zm6 27.5c-1.5 2-3.6 3-6.6 3h-6.4V8.5h6.4c2.9 0 4.6.8 6.3 3 1.4 2 1.6 3.5 1.6 10 0 6.6-.2 7.6-1.4 9.4zM100 3.3h-4.4L82.8 39h5.6l2.8-8h13.3l2.7 8h5.7L100 3.3zm-7.3 22.9l5-15.6h.2l5 15.6H92.8z" />
    <path d="M28.4 9.6a1.8 1.8 0 002.6 0l3-3.2A1.8 1.8 0 0034 4L31 .7a1.8 1.8 0 00-2.6 0l-3 3.2a1.8 1.8 0 00-.4 2c0 .2.2.4.3.5l3.1 3.2zm-2-4.6L29.7 2a.2.2 0 01.2 0L33 5a.2.2 0 010 .3l-3 3.1a.2.2 0 01-.2 0 .2.2 0 01-.1 0l-3.1-3.1a.2.2 0 010-.1.2.2 0 010-.2z" />
  </svg>
);

export { IXDALogo };
