/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Box } from 'rebass';
import { IXDALogoBox } from './IXDALogoBox';
import { CommunicateWithUs } from './CommunicateWithUs';
import { CocPrivacyAndOtherLinks } from './CocPrivacyAndOtherLinks';
import { PartnerFooter } from './PartnerFooter';

declare module 'csstype' {
  interface Properties {
    contentVisibility: 'visible' | 'auto' | 'hidden';
  }
}

const Footer = () => {
  return (
    <footer
      sx={{
        width: '100%',
        pb: 5,
        borderTop: 'solid 1px',
        borderColor: 'text',
        mt: 5,
      }}
      style={{ contentVisibility: 'auto' }}
    >
      <Box sx={{ display: ['block', 'block', 'none'], pt: '30px' }}>
        <CommunicateWithUs />
      </Box>
      <IXDALogoBox />
      <Box
        sx={{
          display: ['none', 'none', 'block'],
          py: 4,
        }}
      >
        <CommunicateWithUs />
      </Box>
      <Box
        sx={{
          borderTop: 'solid 1px',
          borderBottom: 'solid 1px',
          borderColor: 'text',
        }}
      >
        <PartnerFooter />
      </Box>
      <CocPrivacyAndOtherLinks />
    </footer>
  );
};

export default Footer;
