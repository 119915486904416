/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box } from 'rebass';
import { Link as BaseLink } from 'gatsby';
import { useLocale } from '../i18n/LocaleContext';

type Props = {};
const activeStyles = {
  fontWeight: 'bold',
  borderBottomStyle: 'solid',
  borderWidth: '3px',
  borderColor: 'text',
};

const LocalizationNav: React.FC<Props> = () => {
  const { locale, basePath } = useLocale();

  return (
    <Box sx={{ display: ['none', 'none', 'block'], textAlign: 'right' }}>
      <Box sx={{ mb: 3 }}>
        <BaseLink
          to={`${basePath === '/fr' ? '' : basePath}`}
          sx={{
            variant: 'variants.navlink',
            ml: '20px',
            ...(locale === 'en' ? activeStyles : {}),
          }}
        >
          English
        </BaseLink>
        <BaseLink
          to={`/fr${basePath === '/fr' ? '' : basePath}`}
          sx={{
            variant: 'variants.navlink',
            ml: '20px',

            ...(locale === 'fr' ? activeStyles : {}),
          }}
        >
          Français
        </BaseLink>
      </Box>
    </Box>
  );
};

export { LocalizationNav };
