/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Helmet } from 'react-helmet';

import Page from '../components/Page';
import Fonts from '../components/Fonts';

// @ts-ignore
import Spritemap from '../../assets/logo-spritemap.svg';
import theme from '../gatsby-plugin-theme-ui';
import { useTranslation } from '../i18n/TranslationContext';
import { useLocale } from '../i18n/LocaleContext';

import '../../styles/reset.css';
import '../../styles/base.css';

import { Header } from '../components/Header';
import Footer from '../components/Footer';

const MainLayout: React.FC = ({ children, ...restProps }) => {
  const t = useTranslation();

  const { locale } = useLocale();

  return (
    <Page>
      <Spritemap />
      <Fonts />
      <Helmet>
        {/*<script src="https://js.tito.io/v1" async></script>
        <link rel="stylesheet" type="text/css" href='https://css.tito.io/v1.1' />*/}
        <meta name="theme-color" content={theme.colors.primary} />
        <link rel="shortcut icon" href={'/favicon.png'} type="image/x-icon" />
        <title>{t('site_title')}</title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ixdconf" />
        <meta name="twitter:creator" content="@ixda" />
        <meta property="og:url" content="https://interaction21.ixda.org" />
        <meta property="og:title" content={t('site_title')} />
        <meta property="og:description" content={t('meta_description')} />
        <meta property="og:image" content={'/og-image.jpg'} />
        <html lang={locale} />
      </Helmet>
      <a
        tabIndex={1}
        href="#content"
        sx={{
          zIndex: 11,
          backgroundColor: 'text',
          color: 'background',
          fontFamily: 'body',
          height: '30px',
          left: '5px',
          top: '0',
          padding: '8px',
          position: 'fixed',
          transform: 'translateY(-100%)',
          transition: 'transform 0.3s',

          '&:focus': {
            transform: 'translateY(5px)',
          },
        }}
      >
        {t('jump_to_content_button')}
      </a>
      <Header />
      {typeof children === 'function' ? children(restProps) : children}
      <Footer />
    </Page>
  );
};

export default MainLayout;
