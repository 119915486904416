// @ts-ignore
import Balsamiq from '../images/logos/partner-Balsamiq.svg';
// @ts-ignore
import Amazon from '../images/logos/partner-amazon.svg';
// @ts-ignore
import TTCLabs from '../images/logos/partner-TTC-Labs.svg';
// @ts-ignore
import Adobe from '../images/logos/partner-adobe.svg';
// @ts-ignore
import Rosenfeld from '../images/logos/partner-Rosenfeld.svg';
// @ts-ignore
import Protopie from '../images/logos/partner-ProtoPie.svg';
// @ts-ignore
import Bloomberg from '../images/logos/partner-bloomberg.svg';
// @ts-ignore
import Axure from '../images/logos/partner-axure.svg';
// @ts-ignore
import Designit from '../images/logos/partner-designit.svg';
// @ts-ignore
import Mailchimp from '../images/logos/partner-mailchimp.svg';
// @ts-ignore
import Compass from '../images/logos/partner-compass.svg';
// @ts-ignore
import Shopify from '../images/logos/partner-shopify.svg';
// @ts-ignore
import Mckinsey from '../images/logos/partner-mckinsey.svg';
// @ts-ignore
import NWM from '../images/logos/partner-northwestern.svg';
// @ts-ignore
import Microsoft from '../images/logos/partner-microsoft.svg';
// @ts-ignore
import CJR from '../images/logos/partner-cozyjuicyreal.svg';

export default {
  Bloomberg: Bloomberg,
  Compass: Compass,
  Amazon: Amazon,
  balsamiq: Balsamiq,
  ProtoPie: Protopie,
  'TTC Labs': TTCLabs,
  Adobe: Adobe,
  Axure: Axure,
  Designit: Designit,
  Mailchimp: Mailchimp,
  'McKinsey Design': Mckinsey,
  Microsoft: Microsoft,
  'Northwestern Mutual': NWM,
  'Rosenfeld Media': Rosenfeld,
  Shopify: Shopify,
  'Cozy Juicy Real': CJR,
};
