/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import { Box, Flex } from 'rebass';
import * as React from 'react';

import Logo from './Logo';
import { MobileNav } from './MobileNav';
import { LocalizationNav } from './LocalizationNav';
import { Container } from './Container';
import { LocalizedLink as Link } from './LocalizedLink';
import TrackVisibility from 'react-on-screen';
import { ModeSwitcherMobile } from './ModeSwitcherMobile';

const Header: React.FC<{}> = () => {
  const [isLogoVisible, setLogoVisibility] = React.useState(true);
  const [mode, setMode] = useColorMode();

  return (
    <header>
      <MobileNav logoOnScreen={isLogoVisible} />
      <Container my={[4, 4, 5]}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <TrackVisibility partialVisibility>
            {({ isVisible }) => {
              setLogoVisibility(isVisible);
              return (
                <Link to="/" sx={{ display: ['block', 'block', 'none'] }}>
                  <Logo
                    type="interaction-21glitch"
                    fill="text"
                    sx={{ height: '60px', width: ['120px', '230px'] }}
                  />
                </Link>
              );
            }}
          </TrackVisibility>
          <Box
            sx={{ display: ['block', 'block', 'none'] }}
            onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
          >
            <ModeSwitcherMobile invertColors />
          </Box>
          <LocalizationNav />
        </Flex>
      </Container>
    </header>
  );
};

export { Header };
