/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ContainerFlex } from '../Container';
import { P } from '../Text';
import { useTranslation } from '../../i18n/TranslationContext';
import { LocalizedLink as Link } from '../LocalizedLink';

const CocPrivacyAndOtherLinks = () => {
  const t = useTranslation();
  return (
    <ContainerFlex
      justifyContent="space-between"
      py={[4, 4, 5]}
      flexDirection={['column', 'column', 'row']}
    >
      <Link
        sx={{ mb: [3, 3, 0], px: 1, variant: 'variants.link' }}
        to="/code-of-conduct"
      >
        {t('footer_coc')}
      </Link>
      <Link
        sx={{ mb: [3, 3, 0], px: 1, variant: 'variants.link' }}
        to="/privacy-policy"
      >
        {t('footer_privacy')}
      </Link>
      <P sx={{ m: 0, p: 0, px: 1, mb: [3, 3, 0], fontSize: 1, lineHeight: 1 }}>
        {t('footer_copyright')}
      </P>
      <Link sx={{ px: 1, variant: 'variants.link' }} to="/accessibility">
        {t('footer_accessibility')}
      </Link>
    </ContainerFlex>
  );
};

export { CocPrivacyAndOtherLinks };
