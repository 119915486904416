/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, BoxProps, Flex, FlexProps } from 'rebass';

const Container: React.FC<Omit<BoxProps, 'css'>> = ({
  children,
  ...restProps
}) => (
  <Box mx="auto" px={3} maxWidth="1118px" {...restProps}>
    {children}
  </Box>
);

const ContainerFlex: React.FC<Omit<FlexProps, 'css'>> = ({
  children,
  ...restProps
}) => (
  <Flex mx="auto" px={3} maxWidth="1118px" {...restProps}>
    {children}
  </Flex>
);

export { Container, ContainerFlex };
