/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex } from 'rebass';
import { IDESLogo } from './IDESLogo';
import { ISDCLogo } from './ISDCLogo';
import { AwardsLogo } from './AwardsLogo';
import { IXDALogo } from './IXDALogo';
import { Container } from '../Container';

const IXDALogoBox = () => (
  <Container py={[2, 3, 3]}>
    <Flex alignItems="baseline" justifyContent="space-between" flexWrap="wrap">
      <Flex
        justifyContent={['center', 'flex-start']}
        p={[2, null]}
        width={[1 / 2, 1 / 4]}
      >
        <a
          href="https://ixda.org/"
          target="_blank"
          sx={{ variant: 'variants.link' }}
          aria-label="Interaction Design Association"
        >
          <IXDALogo width={['130px']} />
        </a>
      </Flex>
      <Flex justifyContent={['center']} p={[2, null]} width={[1 / 2, 1 / 4]}>
        <a
          href="https://awards.ixda.org/"
          target="_blank"
          sx={{ variant: 'variants.link' }}
          aria-label="Interaction Awards 2021"
        >
          <AwardsLogo width={['130px']} />
        </a>
      </Flex>
      <Flex justifyContent={['center']} p={[2, null]} width={[1 / 2, 1 / 4]}>
        <a
          href="https://www.edusummit.ixda.org/"
          target="_blank"
          sx={{ variant: 'variants.link' }}
          aria-label="Interaction Design Education Summit"
        >
          <IDESLogo width={['130px']} />
        </a>
      </Flex>
      <Flex
        justifyContent={['center', 'flex-end']}
        p={[2, null]}
        width={[1 / 2, 1 / 4]}
      >
        <a
          href="https://www.sdc.ixda.org/"
          target="_blank"
          sx={{ variant: 'variants.link' }}
          aria-label="IxDA Student Design Charette"
        >
          <ISDCLogo width={['130px']} />
        </a>
      </Flex>
    </Flex>
  </Container>
);

export { IXDALogoBox };
