/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Flex, Box, Link, Button } from 'rebass';

import { H4 } from '../Text';
import { Container } from '../Container';

import { useTranslation } from '../../i18n/TranslationContext';
import { LocalizedLink } from '../../components/LocalizedLink';

const JoinTheConvo = () => {
  const t = useTranslation();
  return (
    <Box>
      <H4>{t('footer_join_the_conversation')}</H4>
      <Flex flexDirection="column">
        <a
          href="mailto:interaction21@ixda.org"
          target="_blank"
          sx={{ mb: 3, variant: 'variants.link', display: 'block' }}
        >
          interaction21@ixda.org
        </a>
      </Flex>
    </Box>
  );
};

const SocialMediaSection = () => {
  const t = useTranslation();
  return (
    <Box>
      <H4>{t('footer_follow_the_movement')}</H4>
      <Box sx={{ display: 'inline-block' }}>
        <ul
          sx={{
            m: 0,
            p: 0,
            listStyle: 'none',
            columns: 2,
            li: { mb: 3 },
          }}
        >
          <li>
            <Link href="https://twitter.com/ixdconf" target="_blank">
              Twitter
            </Link>
          </li>
          <li>
            <Link href="https://instagram.com/ixdconf" target="_blank">
              Instagram
            </Link>
          </li>
          <li>
            <Link
              href="https://www.facebook.com/groups/ixdaglobal/"
              target="_blank"
            >
              Facebook
            </Link>
          </li>
          <li>
            <Link href="https://www.linkedin.com/company/ixda" target="_blank">
              LinkedIn
            </Link>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

const NewsletterSignupSection = () => {
  const t = useTranslation();
  return (
    <Box>
      <Button
        as="a"
        target="_blank"
        name="subscribe"
        href="http://eepurl.com/gRTWCP"
        sx={{
          variant: 'buttons.primary',
            textDecoration: 'none',
            mt: 3,
            p: 2,
            cursor: 'pointer',
        }}
      >
        {t('footer_subscribe_btn')}
      </Button>
    </Box>
  );
};

const FooterPartnerWithUs = () => {
  const t = useTranslation();
  return (
    <Flex>
      <LocalizedLink
        to="/partners/partner-with-us"
        sx={{
          variant: 'buttons.primary',
          textDecoration: 'none',
          mt: 3,
          p: 2,
        }}
      >
        {t('footer_get_involved')}
      </LocalizedLink>
    </Flex>
  );
};

const CommunicateWithUs = () => (
  <Container>
    <Flex
      flexDirection={['column-reverse', 'column-reverse', 'row']}
      flexWrap="wrap"
    >
      <Flex
        flexDirection="column"
        width={[1, 1 / 2, 1 / 3]}
        justifyContent="space-between"
        mb={[4, 4, 0]}
      >
        <JoinTheConvo />
        <FooterPartnerWithUs />
      </Flex>
      <Flex
        flexDirection="column"
        width={[1, 1 / 2, 1 / 3]}
        justifyContent="space-between"
        mb={[4, 4, 0]}
      >
        <SocialMediaSection />
      </Flex>
      <Flex
        flexDirection="column"
        width={[1, 1, 1 / 3]}
        justifyContent="space-between"
        mb={[4, 4, 0]}
      >
        <NewsletterSignupSection />
      </Flex>
    </Flex>
  </Container>
);

export { CommunicateWithUs };
