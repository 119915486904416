/** @jsx jsx */
import { number, string } from 'prop-types';
import { Box, Link } from 'rebass';
import { Flex, jsx } from 'theme-ui';
import sponsors from '../components/Sponsors';
import partnersData from '../data/partners/partners.json';
import { Container } from './Container';

interface Partner {
  id: string;
  active: boolean;
  display_footer: string;
  size: string;
  logo: string;
  logoalt: string;
  title: string;
  url: string;
  link: string;
  src: string;
  tier: number;
}

const tierSizes: any = {
  XL: [225, 100],
  L: [180, 80],
  M: [170, 75],
  S: [170, 75],
};

const PartnerFooter: React.FC = () => {
  const partnersByTier = partnersData.reduce(
    (acc, partner, i) => {
      if (partner.active) {
        const { size, link, url, logoalt } = partner;

        const LogoSVG = sponsors[partner.logoalt];
        const sponsorLogo = (
          <Link
            href={url}
            key={`partner_${i}`}
            aria-label={logoalt}
            target="_blank"
          >
            <LogoSVG
              sx={{
                fill: 'text',
                maxWidth: '100%',
              }}
              aria-hidden="true"
              height={tierSizes[size][1]}
            />
          </Link>
        );

        const collection = acc[size] || acc['S'];

        collection.push(sponsorLogo);
      }
      return acc;
    },
    {
      XL: [],
      L: [],
      M: [],
      S: [],
    },
  );

  return (
    <Container>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          {partnersByTier['XL']}
        </Flex>
        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          {partnersByTier['L']}
        </Flex>
        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          {partnersByTier['M']}
        </Flex>
        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          {partnersByTier['S']}
        </Flex>
      </Flex>
    </Container>
  );
};

export { PartnerFooter };

/*

.map(collection => console.log(collection))

*/
